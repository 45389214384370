<script>
import Layout from "../../../layouts/main.vue";
import api from '@/services/secureHttps';
import Spinner from "@/components/spinner";
import { mapGetters } from 'vuex';

export default {

    components: {
        Layout,
        Spinner
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ]),

        displayedSerp() {
            return this.paginate(this.data);
        },

        resultQuery() {
            return this.data;

        }
    },

    watch: {
        /* rows: function (newValue) {
             this.data = newValue;
             this.setPages();
         }*/

        selectedWebisteId: {
            handler: async function (newValue) {
                if (newValue !== 0) {
                    console.log('selectedWebisteId: ', this.selectedWebisteId);
                    api.getWebsiteRules(newValue)
                        .then(response => {

                            console.log(response.data);
                            this.data = response.data;
                            console.log("!!!!!!");
                            this.isLoading = false;
                            this.setPages();
                        });
                }
            }
        }

    },

    mounted() {
        if (this.selectedWebisteId !== 0) {
            api.getWebsiteRules(this.selectedWebisteId)
                .then(response => {

                    console.log(response.data);
                    this.data = response.data;
                    console.log("!!!!!!");
                    this.isLoading = false;
                    this.setPages();
                });
        }
    },


    props: {


        /*   rows: {
               type: Array
           }*/
    },

    data() {
        return {
            data: [],
            page: 1,
            perPage: 10,
            pages: [],
            orderPageDesc: false,
            orderFeatureDesc: false,
            orderDescriptionDesc: false,
            orderScopeDesc: false,
            isLoading: true
        };
    },

    methods: {

        paginate(data) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return data.slice(from, to);
        },

        setPages() {
            console.log('setPages');
            if (this.data === null) {
                return;
            }

            this.updatePages(this.data);
        },

        updatePages(data) {
            console.log('updatePages');
            this.pages = [];
            let numberOfPages = Math.ceil(data.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                console.log("updating pages");
                this.pages.push(index);
            }
        },

        orderbyPage() {
            console.log('sorting page')
            console.log(this.resultQuery);
            if (this.orderPageDesc === false) {
                this.resultQuery.sort((a, b) => {
                    console.log('b: ', b);
                    return b.page.id - a.page.id
                });
                this.orderPageDesc = true;
            } else {
                this.resultQuery.sort((a, b) => a.page.id - b.page.id);
                this.orderPageDesc = false;
            }
        },

        orderbyFeature() {
            console.log("click sorting");
            if (this.orderFeatureDesc === false) {
                this.resultQuery.sort((a, b) => b.feature.id - a.feature.id);
                this.orderFeatureDesc = true;
            } else {
                this.resultQuery.sort((a, b) => a.feature.id - b.feature.id);
                this.orderFeatureDesc = false;
            }
        },

        orderbyDescription() {
            console.log("ctr sorting");
            if (this.orderDescriptionDesc === false) {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(b.rule.description, a.rule.description));
                this.orderDescriptionDesc = true;
            } else {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(a.rule.description, b.rule.description));
                this.orderDescriptionDesc = false;
            }
        },

        orderByScope() {
            console.log("scope sorting");
            if (this.orderScopeDesc === false) {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(this.getScopeLabel(b.rule.scope), this.getScopeLabel(a.rule.scope)));
                this.orderScopeDesc = true;
            } else {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(this.getScopeLabel(a.rule.scope), this.getScopeLabel(b.rule.scope)));
                this.orderScopeDesc = false;
            }
        },

        getPageLabel(pageObj) {
            if (typeof pageObj === 'undefined') {
                return "every page";
            }

            return pageObj.path;
        },

        getScopeLabel(scopeObj) {
            if (typeof scopeObj === 'undefined') {
                return "unkown";
            }

            var label = "";
            for (const [key, value] of Object.entries(scopeObj)) {
                if (value === true) {
                    label += key + ', ';
                }
            }

            label = label.replace(new RegExp(', ' + '$'), '');

            return label;
        },

        sortAlphabetically(nameA, nameB) {
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        }
    }

}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div>
            <div class="row">
                <div>

                    <!-- end col -->
                    <div class="col">
                        <div class="card card-height-100">
                            <div class="card-header">
                                <div class="row">
                                </div>
                                <p class="
                                    text-uppercase
                                    fw-bold
                                    text-truncate
                                    mb-0
                                    ">
                                    Metadata Website Rules
                                </p>
                            </div>


                            <!-- end card header -->

                            <!-- card body -->
                            <div class="card-body">
                                <Spinner :isLoading="isLoading"></Spinner>
                                <p class="mb-3">These are rules that you can create when entering structured data to a page. Rules can
                                    save you having to repeat the same information into the structured data forms.</p>
                                
                                <!-- start table -->
                                <div v-if="data" class="card-body">
                                    <div class="table-responsive table-card">
                                        <table class="table align-middle">
                                            <thead class="table-light text-muted">
                                                <tr>
                                                    <th scope="col" @click="orderbyPage">Page</th>
                                                    <th scope="col" @click="orderbyFeature">Feature</th>
                                                    <th scope="col" @click="orderByScope">Scope</th>
                                                    <th scope="col" @click="orderbyDescription">Rule Description</th>
                                                </tr>
                                            </thead>
                                            <!--end thead-->
                                            <tbody class="list form-check-all">
                                                <tr v-for="(data, index) of displayedSerp" :key="index">
                                                    <td class="col-4 text-wrap">
                                                        {{ getPageLabel(data.page) }}
                                                    </td>
                                                    <td>
                                                        {{ data.feature.label }}
                                                    </td>
                                                    <td>
                                                        {{ getScopeLabel(data.rule.scope) }}
                                                    </td>
                                                    <td>
                                                        {{ data.rule.description }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <!--end tbody-->
                                        </table>
                                    </div>
                                    <div class="d-flex justify-content-end mt-3">
                                        <div class="pagination-wrap hstack gap-2">
                                            <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                                @click="page--">
                                                Previous
                                            </a>
                                            <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                                <li :class="{
                                                    active: pageNumber == page,
                                                    disabled: pageNumber == '...',
                                                }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                                    :key="index" @click="page = pageNumber">
                                                    <a class="page" href="#">{{ pageNumber }}</a>
                                                </li>
                                            </ul>
                                            <a class="page-item pagination-next" href="#" @click="page++"
                                                v-if="page < pages.length">
                                                Next
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->
                </div>
            </div>
        </div>
    </Layout>
</template>